<template>
	<div>
		<ContentHeader title="Laporan HRD" subTitle="Laporan Kontrak Kerja Karyawan" url="laporan-hrd" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
          <div class="card-header">
            <h3 class="card-title">Data Laporan Kontrak Kerja Karyawan</h3>
          </div>
          <div class="card-body" style="display: block;">
          	<div class="row" v-if="toogleCabang">
          		<div class="col-lg-4">
          			<label>Pilih Cabang</label>
          			<multiselect 
				          v-model="cabang_select_value"
				          deselect-label="Can't remove this value"
				          track-by="nama"
				          label="nama"
				          placeholder=""
				          :options="cabang"
				          :loading="loading_cabang"
				          :allow-empty="false"
				          @update:model-value="getDataResult"
				        >
				        </multiselect>
          		</div>
          	</div>
            <table class="table table-bordered table-hover mt-4">
              <thead>
                <tr>
                  <th scope="col" class="text-sm">NO</th>
                  <th scope="col" class="text-sm">NAMA</th>
                  <th scope="col" class="text-sm">JABATAN</th>
                  <th scope="col" class="text-sm">DIVISI</th>
                  <th scope="col" class="text-sm">CABANG</th>
                  <th scope="col" class="text-sm">WILAYAH</th>
                  <th scope="col" class="text-sm">TANGGAL BERGABUNG</th>
                  <th scope="col" class="text-sm">TANGGAL HABIS KONTRAK</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in data_result" :key="row.id">
                  <td class="text-sm p-2" scope="row" >{{ index + 1 }}</td>
                  <td class="text-sm p-2" scope="row" >{{ row.nama_lengkap }}</td>
                  <td class="text-sm p-2" scope="row" >{{ row.jabatan_kerja }}</td>
                  <td class="text-sm p-2" scope="row" >{{ row.divisi }}</td>
                  <td class="text-sm p-2" scope="row" >{{ row.cabang }}</td>
                  <td class="text-sm p-2" scope="row" >{{ row.wilayah }}</td>
                  <td class="text-sm p-2" scope="row" >{{ row.tanggal_bergabung }}</td>
                  <td class="text-sm p-2" scope="row" >{{ row.tanggal_habis_kontrak }}</td>
                </tr>
              </tbody>
            </table>
            <p class="text-center mt-2" v-if="memuat_data">Memuat Data...</p>
            <p class="text-center mt-2">{{ cek_data }}</p>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
          </div>
          <!-- /.card-footer-->
        </div>
			</div>
		</div>
	</div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader2'
import Multiselect from '@suadelabs/vue3-multiselect'

import { ref, computed, onMounted } from 'vue'
import store from '@/store'
import axios from 'axios'


const user = computed(() => store.getters['auth/user'])
const data_result = ref([])
const cek_data = ref('')
const memuat_data = ref(false)
const total = ref('')

const getDataResult = async () => {
    data_result.value = []
    total.value = ''
    memuat_data.value = true
    cek_data.value = ''
    let cabang_id = ''

    if (cabang_select_value.value == '') {
        cabang_id = user.value.cabang_id
    } else {
        cabang_id = cabang_select_value.value.id
    }

    let { data } = await axios.get(`api/karyawan/getKontrakAkanExpired/${cabang_id}`)

    if (data == 'kosong') {
        cek_data.value = 'Data Masih Kosong'
        memuat_data.value = false
        data_result.value = []
    } else {
        memuat_data.value = false
        cek_data.value = ''
        data_result.value = data
    }
}

const toogleCabang = ref(false)
const cabang_select_value = ref('')
const cabang = ref([])
const loading_cabang = ref(true)
const getCabang = async () => {
    let response = await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)

    if (response.data.jenis == 'pusat') {
        toogleCabang.value = true
        let { data } = await axios.get(`api/cabang/getWhereCabang/${response.data.id}`)
        
        if (data != 'kosong') {
            cabang.value = data
            loading_cabang.value = false
        } else {
            cabang.value = []
            loading_cabang.value = false
        }
    }
}


onMounted(() => {
    getCabang()
    getDataResult()
})
</script>